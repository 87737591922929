<template>
  <GeneralDialogTemplate>
    <template slot="header">
      <span class="font-weight-medium">Liikme kustutamine</span>
    </template>
    <v-row
      no-gutters
      class="pa-5 dialog-width"
    >
      <v-col cols="12">
        Liikme <b>{{ dialogPayload.uid }}</b> kustutamine
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="form.method"
          :items="deletionTypes"
        />
      </v-col>
      <v-col cols="12">
        <v-row
          no-gutters
          justify="end"
        >
          <v-btn
            color="primary"
            text
            @click="onCloseClick"
          >
            Loobu
          </v-btn>
          <v-btn
            color="error"
            @click="onDeleteClick"
          >
            <v-icon left>
              {{ mdiDelete }}
            </v-icon>Kustuta
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </GeneralDialogTemplate>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api';
import { mdiDelete } from '@mdi/js';
import GeneralDialogTemplate from '@/components/templates/GeneralDialogTemplate.vue';
import AuthUserModule from '@/store/modules/auth_user';
import dialogModule from '@/store/modules/dialog';

const vectorIcons = { mdiDelete };
export default {
  components: {
    GeneralDialogTemplate,
  },
  setup() {
    const { deleteUser } = AuthUserModule();
    const {
      dialogPayload, closeDialog, openConfirmDialog, setConfirmOptions,
    } = dialogModule();
    const deletionTypes = [
      { text: 'Põhjusega v/a', value: 'pohjusega' },
      { text: 'Põhjuseta v/a', value: 'pohjuseta' },
      { text: 'Kustuta profiil täielikult', value: 'deleteprofile' },
    ];
    const state = reactive({
      form: {
        method: 'pohjuseta',
      },
    });
    const onDeleteConfirm = async () => {
      await deleteUser(dialogPayload.value.uid, state.form.method);
      // snackbar here
      closeDialog();
    };
    const onDeleteClick = () => {
      setConfirmOptions({
        title: 'Liikme kustutamine',
        text: 'Oled kindel et soovid jätkata?',
        confirm_text: 'Kustuta',
        cancel_text: 'Loobu',
        action: () => onDeleteConfirm(),
      });
      openConfirmDialog();
    };
    const onCloseClick = () => {
      closeDialog();
    };
    return {
      ...toRefs(state),
      deletionTypes,
      onDeleteClick,
      onCloseClick,
      dialogPayload,
      ...vectorIcons,
    };
  },
};
</script>
<style lang="scss">
.dialog-width {
    width: 500px
}
</style>
